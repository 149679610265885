.pc-business {
    .page-title {
        width: 36.81rem;
        height: 5rem;
    }

    .business-tip {
        margin-top: 2rem;
        font-size: 1.63rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 2.31rem;
        letter-spacing: 2px;
    }

    .business-container {
        margin-top: 4.38rem;
        width: 100%;
        height: 100%;
        height: ~"calc(100% - 24.88rem)";
        background: #fff;
        padding: 4rem;
    }

    .tabs {
        width: 24rem;
        text-align: right;
    }

    .tab-item {
        margin-bottom: 1.56rem;
        font-size: 2rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;
        line-height: 2.81rem;
        cursor: pointer;

        &:hover {
            opacity: .8;
            transform: scale(1.01);
        }
    }

    .selected-tab {
        font-size: 2.25rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ba9d6e;
        line-height: 3.13rem;
        letter-spacing: 0;
    }

    .divider {
        position: relative;
        width: 0.13rem;
        height: 100%;
        border: 0.06rem solid #D0D0D0;
        margin: 0 3.19rem 0 2.56rem;
    }

    .progress {
        margin-left: -0.15rem;
        width: 0.3rem;
        background: #ba9d6e;
    }

    .circle {
        margin-left: -0.45rem;
        width: 0.94rem;
        height: 0.94rem;
        background: #FFFFFF;
        border-radius: 50%;
        border: 0.28rem solid #ba9d6e;
    }

    .cases {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, 16.44rem);
        grid-template-rows: repeat(auto-fill, 9.44rem);
        grid-gap: 1.81rem 1.5rem;
        overflow-y: scroll;
    }

    .business-item {
        width: 16.44rem;
        height: 9.44rem;
        background: #FFFFFF;

        &:hover {
            .business-top {
                border: 0.17rem solid #BA9D6E;
                border-bottom: none;
            }

            .business-bottom {
                background: #BA9D6E;
                color: #FFFFFF;
                border: 0.17rem solid #BA9D6E;
                border-top: none;
            }
        }
    }

    .business-top {
        width: 100%;
        height: 7rem;
        border: 0.06rem solid #D0D0D0;
        border-bottom: none;
        box-sizing: border-box;
    }

    .business-img {
        width: 100%;
        height: 100%;
    }

    .business-bottom {
        width: 100%;
        height: ~"calc(100% - 7rem)";
        background: #f2f2f2;
        font-size: 1.25rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333;
        line-height: 1.75rem;
        border: 0.06rem solid #D0D0D0;
        border-top: none;
        box-sizing: border-box;
    }

    .selected-item {
        .business-top {
            border: 0.17rem solid #BA9D6E;
            border-bottom: none;
        }

        .business-bottom {
            background: #BA9D6E;
            color: #FFFFFF;
            border: 0.17rem solid #BA9D6E;
            border-top: none;
        }
    }
}