@import './common/style/global.less';

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  box-sizing: border-box
}

.mobile-page {
  position: relative;
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
  }

  .page-content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding: 0 5.56rem;
    z-index: 9;
  }

  .page-title {
    margin-top: 11.25rem
  }

  .page-bottom {
    margin-top: 5.13rem;
    width: 100%;
    height: 100%;
    min-height: ~"calc(100% - 21.25rem)";
    background: #fff
  }
}

@media screen and (min-width: 414px) and (max-width: 499px) {
  html {
    font-size: 8.8px;
  }
}

@media screen and (min-width: 375px) and (max-width: 413px) {
  html {
    font-size: 8px;
  }
}

@media screen and (min-width: 350px) and (max-width: 374px) {
  html {
    font-size: 7px;
  }
}

@media screen and (max-width: 349px),  screen and (max-height: 600px) {
  html {
    font-size: 6px;
  }
}

@font-face {
  font-family: STYuanti-SC-Regular;
  src: url('./common/font/HuaWenYuanTi-REGULAR-2.ttf')format('truetype'),
    url('./common/font/HuaWenYuanTi-REGULAR-2.woff')format('woff');
}

@font-face {
  font-family: Reeji-CloudRuiHei-GBK;
  src: url('./common/font/RuiZiYunZiKuRuiHeiCuGB-2.ttf')format('truetype'),
    url('./common/font/RuiZiYunZiKuRuiHeiCuGB-2.woff')format('woff');
}