.mobile-business {
    .top-cover {
        width: 100%;
        height: 6.75rem;
    }

    .page-content {
        height: ~"calc(100% - 6.75rem)";
        padding: 0;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .page-title {
        margin-top: 0.25rem;
        width: 36.81rem;
        height: 5rem;
    }

    .business-tip {
        margin-top: 2.13rem;
        font-size: 1.75rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 2.63rem;
        letter-spacing: 1px;
    }

    .tabs-container {
        position: sticky;
        top: 0;
        margin-top: 4rem;
        width: 100%;
        padding: 1.5rem 1.88rem;
        background: #fff;
        z-index: 1;
    }

    .tabs {
        width: 100%;
        height: 4.94rem;
        background: #F5F6F7;
        border-radius: 2.47rem;
    }

    .tab-item {
        height: 100%;
        border-radius: 2.47rem;
        padding: 0 2.88rem;
        font-size: 1.75rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 2.5rem;
        transition: all .5s;
    }

    .selected-tab {
        background: #BA9D6E;
        font-size: 1.75rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 2.5rem;
    }

    .cases {
        position: relative;
    }

    .cases-outer {
        width: 100%;
        // min-height: ~"calc(100% - 21.75rem)";
        display: grid;
        grid-template-columns: repeat(3, 14rem);
        grid-template-rows: repeat(auto-fill, 8.44rem);
        grid-gap: 1.25rem 0.63rem;
        justify-content: center;
        padding: 0 1.8rem 1.5rem;
        background: #fff;
        // animation: casesFadeIn .5s ease-in;
    }

    .case-item {
        width: 14rem;
        height: 8.44rem;
        background: #FFFFFF;

        // &:hover {
        //     .business-top {
        //         border: 0.17rem solid #BA9D6E;
        //         border-bottom: none;
        //     }

        //     .business-bottom {
        //         background: #BA9D6E;
        //         color: #FFFFFF;
        //         border: 0.17rem solid #BA9D6E;
        //         border-top: none;
        //     }
        // }
    }

    .business-top {
        width: 100%;
        height: 5.38rem;
        border: 0.06rem solid #D0D0D0;
        border-bottom: none;
        box-sizing: border-box;
        animation: all .5s;
    }

    .business-img {
        width: 100%;
        height: 100%;
    }

    .business-bottom {
        width: 100%;
        height: ~"calc(100% - 5.38rem)";
        background: #f2f2f2;
        font-size: 1.31rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 1.88rem;
        border: 0.06rem solid #D0D0D0;
        border-top: none;
        box-sizing: border-box;
        animation: all .5s;
    }

    .selected-item {
        .business-top {
            border: 0.17rem solid #BA9D6E;
            border-bottom: none;
        }

        .business-bottom {
            background: #BA9D6E;
            color: #FFFFFF;
            border: 0.17rem solid #BA9D6E;
            border-top: none;
        }
    }

    .fix-bottom {
        position: fixed;
        width: 100%;
        height: ~"calc(100% - 21.75rem)";
        bottom: 0;
        background: #fff;
        z-index: -1;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    .to-up {
        animation: casesFadeOut .3s ease-out, casesUpIn .5s ease-in .3s;
    }

    .to-down {
        animation: casesFadeOut .3s ease-out, casesDownIn .5s ease-in .3s;
    }

    .to-left {
        animation: casesFadeOut .3s ease-out, casesLeftIn .5s ease-in .3s;
    }

    .to-right {
        animation: casesFadeOut .3s ease-out, casesRightIn .5s ease-in .3s;
    }

    @keyframes casesFadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes casesFadeOut {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    @keyframes casesUpIn {
        from {
            opacity: 0;
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
        }
    
        to {
            opacity: 1;
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
    }

    @keyframes casesDownIn {
        from {
            opacity: 0;
            -webkit-transform: translateY(0) scaleY(0);
            transform: translateY() scaleY(0);
        }
        
        20% {
            opacity: 0;
        }
        
        50% {
            opacity: .3;
            -webkit-transform: translateY(0) scaleY(.3);
            transform: translateY() scaleY(.3);
        }
    
        to {
            opacity: 1;
            -webkit-transform: translateY(0) scaleY(1);
            transform: translateY(0) scaleY(1);
        }
    }

    @keyframes casesLeftIn {
        from {
            opacity: 0;
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }
    
        to {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }

    @keyframes casesRightIn {
        from {
            opacity: 0;
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
        }
    
        to {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }
}