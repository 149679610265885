.mobile-join-fragment {
    height: 100%;

    .mobile-join {
        height: 100%;
        overflow-y: hidden;

        .page-item {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            overflow: hidden;
            z-index: 9;

            .screen-bg {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: -2;
            }
        }

        .top-cover {
            position: absolute;
            top: 0;
            width: 100%;
            height: 6.75rem;
            background: #fff;
            z-index: 99;
        }

        .join-content {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 6.75rem 1.88rem 0;
            overflow-y: scroll;
        }

        .join-title {
            margin-top: 0.38rem;
            width: 16.06rem;
            height: 4.94rem;
        }

        .item-container {
            width: 100%;
            padding: 1.88rem 1.88rem 2.63rem;
            background: #fff;
            margin-bottom: 1.88rem;
        }

        .post {
            font-size: 2.63rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #BA9D6E;
            line-height: 3.63rem;
        }

        .post-title {
            margin-top: 1.5rem;
            margin-bottom: 0.63rem;
            font-size: 2rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 2.75rem;
        }

        .post-item {
            font-size: 1.63rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 2.75rem;
        }

        .contact-content {
            height: 100%;
            padding: 0 5.56rem;
        }

        .contact-title {
            margin-top: 7.13rem;
            width: 25.44rem;
            height: 4.88rem;
        }

        .contact-bottom {
            width: 100%;
            height: ~"calc(100% - 12rem)";
            max-height: 70rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;


            &>div {
                // margin-top: 5.5rem;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .label {
                font-size: 2rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ba9d6e;
                line-height: 2.75rem;
                margin-bottom: 1.13rem;
            }

            .text {
                font-size: 1.75rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 2.5rem;
            }

            .code {
                width: 16rem;
                height: 16rem;
            }
        }

        .plus-outer {
            position: absolute;
            top: 0;
            height: 0;
            width: 100%;
            height: 100%;
            z-index: 999;
        }

        .plus-code {
            width: 30rem;
            height: 30rem;
            box-shadow: 0 0 100vw 100vh rgba(0, 0, 0, .3);
        }

        .active {
            opacity: 1;
            z-index: 99;
        }
    }

    .up {
        .before {
            animation: fadeOutUp .5s ease-out;
            z-index: 98 !important;
        }

        .active {
            animation: fadeInUp 1s ease-in;
        }
    }

    .down {
        .before {
            animation: fadeOutDown .5s ease-out;
            z-index: 98 !important;
        }

        .active {
            animation: fadeInDown 1s ease-in;
        }
    }

    .first-load {

        .page-item,
        .active {
            animation: none;
        }
    }

    @keyframes fadeOutUp {
        from {
            top: 0;
            opacity: 1;
        }

        to {
            top: -100%;
            opacity: 0;
        }
    }

    @keyframes fadeInUp {
        from {
            top: 100%;
            opacity: 0;
        }

        50% {
            top: 0;
            opacity: 0;
        }

        to {
            top: 0;
            opacity: 1;
        }
    }

    @keyframes fadeOutDown {
        from {
            top: 0;
            opacity: 1;
        }

        to {
            top: 100%;
            opacity: 0;
        }
    }

    @keyframes fadeInDown {
        from {
            top: -100%;
            opacity: 0;
        }

        50% {
            top: 0;
            opacity: 0;
        }

        to {
            top: 0;
            opacity: 1;
        }
    }
}