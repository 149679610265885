.pc-detail-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    animation: moveIn .5s ease-in-out;

    .left {
        width: ~"calc(100% - 69.63rem)";
        height: 100%;
    }

    .right {
        position: relative;
        width: 69.63rem;
        height: 100%;
        background: #FFFFFF;
        padding: 0 3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .right1,
    .right2 {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
    }

    .right1 {
        width: 71.51rem;
        background: #F6F6F6;
        opacity: 0.6;
        z-index: -1;
    }

    .right2 {
        width: 73.39rem;
        background: #FAFAFA;
        opacity: 0.29;
        z-index: -2;
    }

    .close {
        position: absolute;
        top: 3.75rem;
        right: 4.81rem;
        width: 1.63rem;
        height: 1.63rem;
        cursor: pointer;
    }

    .arrow {
        width: 1.19rem;
        height: 2rem;
        cursor: pointer;
    }

    .outer {
        width: 52rem;
        height: 100%;
        padding: 6.25rem 0;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .outer-content {
        color: #333;
        animation: fadeInDetail 1s;
    }

    .to-left {
        animation: toLeftDetail .5s;
    }

    .to-right {
        animation: toRightDetail .5s;
    }

    .detail-img {
        max-width: 30rem;
        max-height: 5rem;
    }

    .sub-img {
        width: 100%;
        height: auto;
    }

    .detail-title {
        margin-top: 3rem;
        margin-bottom: 1.25rem;
        width: 100%;
        font-size: 2.25rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 3.31rem;
    }

    .sub-img {
        margin-top: 0.81rem;
    }

    .detail-text {
        margin-bottom: 2.38rem;
        width: 100%;
        font-size: 1.38rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 2.38rem;
        text-align: justify;
    }

    .mb0 {
        margin-bottom: 0;
    }

    .mt25 {
        margin-top: 2.5rem;
    }

    .bold {
        font-weight: bold;
    }

    .bold-mb0 {
        .bold;
        .mb0
    }

    .bold-mt25 {
        .bold;
        .mt25
    }

    .c-bold-mb0 {
        .bold-mb0;
        text-align: center;
    }

    .c-bold {
        .bold;
        text-align: center;
    }

    .first-text {
        margin-top: 0.88rem;
    }

    .detail-img2 {
        width: 20.94rem;
        height: 31.06rem;
    }

    .content-img {
        margin: 1.5rem 0;
    }

    @keyframes bounceInUp {

        0%,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
            animation-timing-function: cubic-bezier(.215, .61, .355, 1)
        }

        0% {
            opacity: 0;
            -webkit-transform: translate3d(3000rem, 0, 0) scaleX(5);
            transform: translate3d(3000rem, 0, 0) scaleX(5)
        }

        60% {
            opacity: .6;
            -webkit-transform: translate3d(-5rem, 0, 0) scaleX(.9);
            transform: translate3d(-5rem, 0, 0) scaleX(.9)
        }

        75% {
            opacity: .75;
            -webkit-transform: translate3d(2rem, 0, 0) scaleX(.95);
            transform: translate3d(2rem, 0, 0) scaleX(.95)
        }

        90% {
            opacity: .9;
            -webkit-transform: translate3d(-1rem, 0, 0) scaleX(.985);
            transform: translate3d(-1rem, 0, 0) scaleX(.985)
        }

        to {
            opacity: 1;
            -webkit-transform: translateZ(0);
            transform: translateZ(0)
        }
    }

    @keyframes moveIn {
        from {
            opacity: 0;
            -webkit-transform: translate3d(69.63rem, 0, 0);
            transform: translate3d(69.63rem, 0, 0)
        }

        to {
            opacity: 1;
        }
    }

    @keyframes moveOut {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(69.63rem, 0, 0);
            transform: translate3d(69.63rem, 0, 0)
        }
    }

    @keyframes fadeInDetail {

        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes toLeftDetail {
        from {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }

        to {
            opacity: 0;
            -webkit-transform: translateX(52rem);
            transform: translateX(52rem);
        }
    }

    @keyframes toRightDetail {
        from {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }

        to {
            opacity: 0;
            -webkit-transform: translateX(-52rem);
            transform: translateX(-52rem);
        }
    }
}

.detail-out {
    animation: moveOut .5s ease-in-out;
}