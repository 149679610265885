.pc-team {
    .page-content {
        padding: 0 5.56rem;
    }

    .page-title {
        width: 22.81rem;
        height: 4.88rem;
    }

    .team-tip {
        margin-top: 2.06rem;
        font-size: 1.63rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 2.31rem;
        letter-spacing: 2px;
    }

    .team-imgs {
        margin-top: 4.5rem;
        width: 120rem;
        height: ~"calc(100% - 25rem)";
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1.8rem 3.3rem;
        overflow: scroll;
        padding-bottom: 2rem;
    }

    .img-box {
        position: relative;
        width: 26.5rem;
        height: 39.38rem;
        cursor: pointer;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .default {
            z-index: 10;
        }

        .active {
            z-index: 9;
        }

        .name-cover {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 8.38rem;
            background: linear-gradient(180deg, rgba(66, 61, 58, 0) 0%, #363131 100%);
            opacity: 0.58;
            z-index: 99;
        }

        .name {
            position: absolute;
            left: 2.44rem;
            bottom: 2.5rem;
            font-size: 1.75rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 2.5rem;
            z-index: 200;

            .name-line {
                width: 5.63rem;
                height: 0.06rem;
            }
        }

        .light {
            color: #fff;

            .name-line {
                background: #FFFFFF;
            }
        }

        .dark {
            color: #333;

            .name-line {
                background: #333;
            }
        }

        &:hover {
            .active {
                z-index: 11;
            }

            .name {
                color: #BA9D6E;

                .name-line {
                    background: #BA9D6E;
                }
            }
        }
    }

    .selected {
        .active {
            z-index: 11;
        }

        .name {
            color: #BA9D6E;

            .name-line {
                background: #BA9D6E;
            }
        }
    }

    .mask {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, .6);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;

        .detail-wrapper {
            position: relative;
            width: 99.38rem;
            height: 58.5rem;
            background: #fff;
            padding: 3.38rem 3.63rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .close {
                position: absolute;
                top: 2.13rem;
                right: 2.44rem;
                cursor: pointer;
            }

            .arrow {
                width: 1.19rem;
                height: 2rem;
                cursor: pointer;
            }

            .detail-box {
                width: 80.69rem;
                height: 100%;
                overflow: hidden;
            }

            .box-content {
                height: 100%;
                display: flex;
                animation: fadeUp 1s ease-in-out;
            }

            .left {
                animation: toLeft .5s;
            }

            .right {
                animation: toRight .5s;
            }

            .avatar {
                width: 26.56rem;
                height: 39.38rem;
            }

            .detail-right {
                margin-left: 2.5rem;
                width: 100%;
                height: 100%;
                overflow-y: scroll;
            }

            .cn-name {
                font-size: 3.56rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #152E56;
                line-height: 4.94rem;
                letter-spacing: 3px;
            }

            .en-name {
                font-size: 2.25rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #121212;
                line-height: 3.13rem;
            }

            .team-title {
                margin-top: 1.25rem;
                font-size: 1.5rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 2.06rem;
            }

            .team-content {
                margin-top: 2.55rem;
                text-align: justify;
            }

            .experience-item {
                margin-bottom: 1.5rem;
            }

            .item-text {
                width: 50.63rem;
                font-size: 1.38rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 2.13rem;
            }

            .extra {
                .experience-item {
                    margin-bottom: 1.2rem;
                }

                .item-text {
                    font-size: 1.25rem;
                    line-height: 2rem;
                }
            }
        }
    }

    @keyframes fadeUp {

        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes toLeft {
        from {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }

        to {
            opacity: 0;
            -webkit-transform: translateX(80.69rem);
            transform: translateX(80.69rem);
        }
    }

    @keyframes toRight {
        from {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }

        to {
            opacity: 0;
            -webkit-transform: translateX(-80.69rem);
            transform: translateX(-80.69rem);
        }
    }
}