@import './common/style/global.less';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  box-sizing: border-box
}

.pc-page {
  position: relative;
  width: 100%;
  min-width: 800px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
  }

  .page-content {
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 700px;
    overflow: scroll;
    padding: 0 5.56rem;
    z-index: 9;
  }

  .page-title {
    margin-top: 11.25rem
  }

  .page-bottom {
    margin-top: 5.13rem;
    width: 100%;
    height: 100%;
    min-height: ~"calc(100% - 21.25rem)";
    background: #fff
  }
}

.generate-screen1(16);
.generate-screen2(12);

.generate-screen1(@n, @i: 14) when (@i =< @n) {
  @media screen and (min-width: @i * 100px) and (max-width: @i * 100px + 100px) {
    html {
      font-size: @i * 1px - 4px;
    }
  }

  .generate-screen1(@n, (@i + 1))
}

.generate-screen2(@n, @i: 8) when (@i =< @n) {
  @media screen and (min-width: @i * 100px) and (max-width: @i * 100px + 100px) {
    html {
      font-size: @i * 1px - 2px;
    }
  }

  .generate-screen2(@n, (@i + 1))
}

@media screen and (min-width: 500px) and (max-width: 800px) {
  html {
    font-size: 6px;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  html {
    font-size: 10px;
  }
}

@media screen and (min-width: 1700px) {
  html {
    font-size: 12px;
  }
}

// @media screen and (min-width: 800px) and (max-width: 1200px) {
//   html {
//     font-size: 10px;
//   }
// }

// @media screen and (min-width: 1200px) and (max-width: 1400px) {
//   html {
//     font-size: 12px;
//   }
// }

// @media screen and (min-width: 1400px) and (max-width: 1600px) {
//   html {
//     font-size: 14px;
//   }
// }

// @media screen and (min-width: 1600px) {
//   html {
//     font-size: 16px;
//   }
// }

@font-face {
  font-family: STYuanti-SC-Regular;
  src: url('./common/font/HuaWenYuanTi-REGULAR-2.ttf')format('truetype'),
    url('./common/font/HuaWenYuanTi-REGULAR-2.woff')format('woff');
}

@font-face {
  font-family: Reeji-CloudRuiHei-GBK;
  src: url('./common/font/RuiZiYunZiKuRuiHeiCuGB-2.ttf')format('truetype'),
    url('./common/font/RuiZiYunZiKuRuiHeiCuGB-2.woff')format('woff');
}

::-webkit-scrollbar {
  display: none;
}