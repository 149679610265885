.pc-header {
    .logo {
        position: fixed;
        left: 5.63rem;
        top: 3.75rem;
        width: 15rem;
        height: 3.56rem;
        z-index: 999;
        cursor: pointer;
    }

    .menu {
        position: fixed;
        right: 5.63rem;
        top: 3.75rem;
        width: 3.13rem;
        height: 2.25rem;
        z-index: 999;
        cursor: pointer;

        &:hover {
            transform: scale(1.05);
        }
    }

    .home-logo,
    .home-menu {
        position: absolute;
        z-index: 0;
    }

    .menu-container {
        position: fixed;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        z-index: 9999;
        animation: moveIn .5s ease-in-out;

        .left {
            width: ~"calc(100% - 46.25rem)";
            height: 100%;
        }

        .right {
            position: relative;
            width: 46.25rem;
            height: 100%;
            background: #FFFFFF;
            padding: 6.25rem 0 2.5rem;
        }

        .right1,
        .right2 {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
        }

        .right1 {
            width: 48.12rem;
            background: #F6F6F6;
            opacity: 0.6;
            z-index: -1;
        }

        .right2 {
            width: 50rem;
            background: #FAFAFA;
            opacity: 0.29;
            z-index: -2;
        }

        .close {
            position: absolute;
            top: 3.81rem;
            right: 5.75rem;
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;
        }

        .menu-logo {
            width: 18.13rem;
            height: 4.25rem;
            cursor: pointer;
        }

        .menu-list {
            height: 100%;
            margin: 5rem 0 0;
        }

        .menu-item {
            margin-bottom: 4.25rem;
            font-size: 1.75rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 2.5rem;
            cursor: pointer;

            &:hover {
                opacity: .8;
                transform: scale(1.05);
            }
        }

        .menu-bottom-tip {
            position: absolute;
            bottom: 1.38rem;
            margin: 0 auto;
            text-align: center;

            a {
                font-size: 0.94rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 1.31rem;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .beian {
                margin-right: 0.5rem;
                width: 1.25rem;
                height: 1.25rem;
            }
        }
    }

    .menu-out {
        animation: moveOut .5s ease-in-out;
    }

    @keyframes bounceInUp {

        0%,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
            animation-timing-function: cubic-bezier(.215, .61, .355, 1)
        }

        0% {
            opacity: 0;
            -webkit-transform: translate3d(3000rem, 0, 0) scaleX(5);
            transform: translate3d(3000rem, 0, 0) scaleX(5)
        }

        60% {
            opacity: .6;
            -webkit-transform: translate3d(-5rem, 0, 0) scaleX(.9);
            transform: translate3d(-5rem, 0, 0) scaleX(.9)
        }

        75% {
            opacity: .75;
            -webkit-transform: translate3d(2rem, 0, 0) scaleX(.95);
            transform: translate3d(2rem, 0, 0) scaleX(.95)
        }

        90% {
            opacity: .9;
            -webkit-transform: translate3d(-1rem, 0, 0) scaleX(.985);
            transform: translate3d(-1rem, 0, 0) scaleX(.985)
        }

        to {
            opacity: 1;
            -webkit-transform: translateZ(0);
            transform: translateZ(0)
        }
    }

    @keyframes moveIn {
        from {
            opacity: 0;
            -webkit-transform: translate3d(46.24rem, 0, 0);
            transform: translate3d(46.25rem, 0, 0)
        }

        to {
            opacity: 1;
        }
    }

    @keyframes moveOut {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            -webkit-transform: translate3d(46.24rem, 0, 0);
            transform: translate3d(46.25rem, 0, 0)
        }
    }
}