.mobile-header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    .logo {
        position: fixed;
        left: 1.88rem;
        top: 2.25rem;
        width: 14.74rem;
        height: 3.5rem;
        z-index: 999;
        cursor: pointer;
    }

    .menu {
        position: fixed;
        right: 1.88rem;
        top: 2.25rem;
        width: 3.88rem;
        height: 2.75rem;
        z-index: 999;
        cursor: pointer;

        &:hover {
            transform: scale(1.05);
        }
    }

    .home-logo,
    .home-menu {
        position: absolute;
        z-index: 0;
    }

    .menu-container {
        position: fixed;
        top: 0;
        right: 0;
        width: 300vw;
        height: 300vh;
        background: rgba(5, 5, 5, .6);
        animation: mobileMoveIn .5s ease;
        z-index: 9999;

        .left {
            width: ~"calc(100% - 24.75rem)";
            height: 100%;
        }

        .right {
            position: relative;
            width: 24.75rem;
            height: 100%;
            background: #FFFFFF;
            padding: 12.5rem 0 2.5rem;
        }

        .close {
            position: absolute;
            top: 2rem;
            right: 1.88rem;
            width: 2.63rem;
            height: 2.63rem;
        }

        .menu-logo {
            width: 14.74rem;
            height: 3.44rem;
        }

        .menu-list {
            height: 100%;
            margin: 5rem 0 0;
        }

        .menu-item {
            margin-bottom: 5.635rem;
            font-size: 2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555657;
            line-height: 2.75rem;
        }

        .active-item {
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #BA9D6E;
        }

        .bottom-tip {
            font-size: 0.94rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 1.31rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-decoration: none;

            &:last-child {
                margin-top: 0.63rem;
            }
        }
    }

    .menu-out {
        animation: mobileMoveOut .5s ease;
    }

    @keyframes bounceInUp {

        0%,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
            animation-timing-function: cubic-bezier(.215, .61, .355, 1)
        }

        0% {
            opacity: 0;
            -webkit-transform: translate3d(3000rem, 0, 0) scaleX(5);
            transform: translate3d(3000rem, 0, 0) scaleX(5)
        }

        60% {
            opacity: .6;
            -webkit-transform: translate3d(-5rem, 0, 0) scaleX(.9);
            transform: translate3d(-5rem, 0, 0) scaleX(.9)
        }

        75% {
            opacity: .75;
            -webkit-transform: translate3d(2rem, 0, 0) scaleX(.95);
            transform: translate3d(2rem, 0, 0) scaleX(.95)
        }

        90% {
            opacity: .9;
            -webkit-transform: translate3d(-1rem, 0, 0) scaleX(.985);
            transform: translate3d(-1rem, 0, 0) scaleX(.985)
        }

        to {
            opacity: 1;
            -webkit-transform: translateZ(0);
            transform: translateZ(0)
        }
    }

    @keyframes mobileMoveIn {
        from {
            opacity: 0;
            -webkit-transform: translateX(24.75rem);
            transform: translateX(24.75rem)
        }

        to {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0)
        }
    }

    @keyframes mobileMoveOut {
        from {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0)
        }

        to {
            opacity: 0;
            -webkit-transform: translateX(24.75rem);
            transform: translateX(24.75rem)
        }
    }
}