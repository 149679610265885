.pc-development {
    overflow-y: hidden;
    .page-content1 {
        position: relative;
        width: 100%;
        height: ~"calc(100% - 11.19rem)";
        overflow: scroll;
        padding: 0 5.56rem;
        flex-grow: 1;
        z-index: 9;
    }

    .page-title {
        position: fixed;
        top: 0;
        width: 27.56rem;
        height: 4.94rem;
        z-index: 99;
    }

    .top-cover {
        width: 100%;
        height: 11.19rem;
        z-index: -1;
    }

    .development-bottom {
        position: relative;
        margin-top: 10.06rem;
        width: 100%;
        height: auto;
        // height: ~"calc(100% - 11.19rem)";
        padding: 0 11.25rem 0 0;
        background: #fff;
        display: flex;
    }

    .bottom-left {
        position: relative;
        width: 11.25rem;
        height: 100%;
    }

    .left-title {
        position: sticky;
        top: 2.56rem;
        left: 1.88rem;
        width: 5.13rem;
        height: 27.56rem;
    }

    .bottom-right {
        width: 100%;
        height: 100%;
        overflow: scroll;
    }

    .item-container {
        width: 100%;
        height: 15rem;
    }

    .item-container:first-child {
        margin-top: 2.5rem;
    }

    .item-img {
        width: 25rem;
        height: 15rem;
        margin: auto 0;
    }

    .item-content {
        margin-left: 2.5rem;
        width: 100%;
        height: 100%;
    }

    .item-title {
        font-size: 1.75rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 2.63rem;
    }

    .item-desc {
        margin-top: 0.88rem;
        font-size: 1.38rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 2.5rem;
    }

    .item-date {
        font-size: 1.25rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 1.75rem;
    }

    .divider {
        width: 100%;
        height: 0.06rem;
        background: #E4E4E4;
        margin: 3.75rem 0;
    }

    .pagination {
        margin-top: 10rem;
        margin-bottom: 8.94rem;
        display: flex;
        justify-content: flex-end;

        .ant-pagination {

            .ant-pagination-disabled .ant-pagination-item-link,
            .ant-pagination-disabled:hover .ant-pagination-item-link,
            .ant-pagination-disabled:focus-visible .ant-pagination-item-link,
            .ant-pagination-prev .ant-pagination-item-link,
            .ant-pagination-next .ant-pagination-item-link {
                border: none;
            }

            .ant-pagination-item {
                border-radius: 50%;

                &:hover {
                    border-color: #999;

                    a {
                        color: rgba(0, 0, 0, 0.95);
                    }
                }
            }

            .ant-pagination-item-active {
                background: #000;

                &:hover {
                    opacity: .85;

                    a {
                        color: #fff;
                    }
                }

                a {
                    color: #fff;
                }
            }
        }
    }
}