.mobile-team {
    .page-content1 {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: scroll;
        padding-top: 6.75rem;
        z-index: 9;
    }

    .top-cover {
        position: absolute;
        top: 0;
        width: 100%;
        height: 6.75rem;
        background: #fff;
        z-index: 99;
    }

    .page-title {
        margin-top: 0.38rem;
        width: 22.81rem;
        height: 4.88rem;
    }

    .team-tip {
        margin-top: 2rem;
        font-size: 1.75rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 2.75rem;
    }

    .team-imgs {
        margin-top: 6.13rem;
        width: 100%;
        padding-bottom: 2rem;
    }

    .item-img {
        margin-top: 3.63rem;
        position: relative;
        width: 26.63rem;
        height: 39.38rem;
        &:first-child {
            margin-top: 0;
        }
    }

    @keyframes fadeUp {

        from {
            opacity: 0;
        }
    
        to {
            opacity: 1;
        }
    }
    
    @keyframes toLeft {
        from {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    
        to {
            opacity: 0;
            -webkit-transform: translateX(80.69rem);
            transform: translateX(80.69rem);
        }
    }
    
    @keyframes toRight {
        from {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    
        to {
            opacity: 0;
            -webkit-transform: translateX(-80.69rem);
            transform: translateX(-80.69rem);
        }
    }
}