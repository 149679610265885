.mobile-detail-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100%;
    z-index: 9999;
    animation: moveIn .5s ease-in-out;

    .right {
        position: relative;
        width: 100vw;
        height: 100%;
        background: #FFFFFF;
    }

    .top-tab {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 6.75rem;
        background: #fff;
    }

    .close {
        position: absolute;
        top: 2rem;
        right: 1.88rem;
        width: 1.63rem;
        height: 1.63rem;
    }

    .outer {
        width: 100%;
        height: 100%;
        padding: 6.75rem 1.88rem 10.5rem;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .outer-content {
        color: #555657;
        animation: fadeInDetail 1s;
    }

    .to-left {
        animation: toLeftDetail .5s;
    }

    .to-right {
        animation: toRightDetail .5s;
    }

    .detail-img {
        max-width: 36rem;
        max-height: 6rem;
    }

    .sub-img {
        width: 100%;
        height: auto;
    }

    .detail-title {
        margin-top: 3rem;
        margin-bottom: 1.25rem;
        width: 100%;
        font-size: 2.63rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 4rem;
    }

    .sub-img {
        margin-top: 0.81rem;
    }

    .detail-text {
        margin-bottom: 2.38rem;
        width: 100%;
        font-size: 1.75rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #555657;
        line-height: 3rem;
        text-align: justify;
    }

    .mb0 {
        margin-bottom: 0;
    }

    .mt25 {
        margin-top: 2.5rem;
    }

    .bold {
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bold;
    }

    .bold-mb0 {
        .bold;
        .mb0
    }

    .bold-mt25 {
        .bold;
        .mt25
    }

    .c-bold-mb0 {
        .bold-mb0;
        text-align: center;
    }

    .c-bold {
        .bold;
        text-align: center;
    }

    .first-text {
        margin-top: 0.88rem;
    }

    .detail-img2 {
        width: 20.94rem;
        height: 31.06rem;
    }

    .content-img {
        margin: 1.5rem 0;
    }

    .bottom-bar {
        position: fixed;
        left: ~"calc(50vw - 21.5rem)";
        bottom: 4rem;
        width: 43rem;
        height: 6.25rem;
        background: #F7F7F7;
        box-shadow: 0rem 0.25rem 1.5rem 0rem rgba(100, 101, 102, 0.26);
        border-radius: 3.13rem;
        padding: 0 1.75rem;
    }

    .left-arrow {
        width: 1.5rem;
        margin-right: 1rem;
    }

    .right-arrow {
        width: 1.5rem;
        margin-left: 1rem;
    }

    .change-btn {
        font-size: 1.75rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 2.5rem;
    }

    .team-top {
        width: 100%;
    }

    .team-avatar {
        width: 21rem;
        height: 21rem;
    }

    .team-info {
        margin-left: 1.88rem;
    }

    .cn-name {
        font-size: 3.56rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #152E56;
        line-height: 5rem;
        letter-spacing: 1px;
    }

    .en-name {
        font-size: 2.25rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #121212;
        line-height: 3.13rem;
    }

    .team-title {
        margin-top: 1.25rem;
        font-size: 1.5rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 2.13rem;
    }

    .team-experience {
        margin-top: 3rem;
    }

    .experience-item {
        font-size: 1.75rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #555657;
        line-height: 3rem;
        margin-bottom: 1.75rem;
    }

    @keyframes bounceInUp {

        0%,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
            animation-timing-function: cubic-bezier(.215, .61, .355, 1)
        }
    
        0% {
            opacity: 0;
            -webkit-transform: translate3d(3000rem, 0, 0) scaleX(5);
            transform: translate3d(3000rem, 0, 0) scaleX(5)
        }
    
        60% {
            opacity: .6;
            -webkit-transform: translate3d(-5rem, 0, 0) scaleX(.9);
            transform: translate3d(-5rem, 0, 0) scaleX(.9)
        }
    
        75% {
            opacity: .75;
            -webkit-transform: translate3d(2rem, 0, 0) scaleX(.95);
            transform: translate3d(2rem, 0, 0) scaleX(.95)
        }
    
        90% {
            opacity: .9;
            -webkit-transform: translate3d(-1rem, 0, 0) scaleX(.985);
            transform: translate3d(-1rem, 0, 0) scaleX(.985)
        }
    
        to {
            opacity: 1;
            -webkit-transform: translateZ(0);
            transform: translateZ(0)
        }
    }
    
    @keyframes moveIn {
        from {
            -webkit-transform: translate3d(100vw, 0, 0);
            transform: translate3d(100vw, 0, 0)
        }
    
        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0)
        }
    }
    
    @keyframes moveOut {
        from {
            opacity: 1;
        }
    
        to {
            opacity: 0;
            -webkit-transform: translate3d(100vw, 0, 0);
            transform: translate3d(100vw, 0, 0)
        }
    }
    
    @keyframes fadeInDetail {
    
        from {
            opacity: 0;
        }
    
        to {
            opacity: 1;
        }
    }
    
    @keyframes toLeftDetail {
        from {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    
        to {
            opacity: 0;
            -webkit-transform: translateX(100vw);
            transform: translateX(100vw);
        }
    }
    
    @keyframes toRightDetail {
        from {
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    
        to {
            opacity: 0;
            -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
        }
    }
}

.detail-out {
    animation: moveOut .5s ease-in-out;
}
