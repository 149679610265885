.mobile-home-fragment {
    height: 100%;

    .mobile-home {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .page-item {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
            opacity: 0;
            z-index: 9;

            .screen-bg {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: -2;
            }

            .screen-bg1 {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                z-index: -2;
            }

            .page-num {
                position: fixed;
                right: 5.44rem;
                bottom: 2.19rem;
                width: 3.38rem;
                height: 6.44rem;
                z-index: 99;
            }

            .next-arrow {
                position: fixed;
                margin: 0 auto;
                bottom: 2.31rem;
                cursor: pointer;
                animation: up_down .75s infinite ease-in-out alternate;
                z-index: 100;
            }

            .screen1-title {
                font-size: 1.75rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 5.25rem;
                letter-spacing: 1px;
            }

            .screen1-content {
                width: 38.13rem;
                height: 9.88rem;
            }

            .screen1-line {
                width: 36.13rem;
                height: 0.5rem;
                margin-top: 2.88rem;
            }

            .screen1-desc {
                margin-top: 1.13rem;
                font-size: 1.75rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #F6D093;
                line-height: 3.5rem;
                letter-spacing: 3px;
            }

            .screen2-title {
                margin-top: 7.13rem;
                width: 36.81rem;
                height: 5rem;
            }

            .screen2-desc {
                margin-top: 2rem;
                font-size: 1.75rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 2.63rem;
                letter-spacing: 1px;
            }

            .screen2-content {
                margin-top: 5.75rem;
            }

            .screen2-item {
                margin-top: 5.5rem;
                width: 35.38rem;
                height: 13.25rem;

                &:first-child {
                    margin-top: 0;
                }
            }

            .item2-content {
                position: relative;
                width: 100%;
                height: 100%;
                padding: 4.75rem 2.5rem 3.5rem;
                font-size: 1.5rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #555657;
                line-height: 2.5rem;
            }

            .item2-content-bg {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
            }

            .screen3-title {
                margin-top: 7rem;
                width: 33.38rem;
                height: 6.75rem;
            }

            .service-tip {
                margin-top: 2.13rem;
                font-size: 1.75rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 2.63rem;
                letter-spacing: 1px;
            }

            .service-bottom {
                margin-top: 6.38rem;
                width: 100%;
                position: relative;
            }

            .step-item {
                position: relative;
                width: 7.5rem;
                height: 7.5rem;
            }

            .step-icon {
                width: 100%;
                height: 100%;
            }

            .step-text {
                position: absolute;
                left: -6rem;
                top: 7.63rem;
                width: 19.5rem;
                font-size: 1.5rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #BA9D6E;
                line-height: 4.38rem;
                text-align: center;
            }

            .x-arrow {
                margin: 0 1.13rem;
                width: 12.13rem;
                height: 1.75rem;
            }

            .y-arrow {
                position: absolute;
                width: 1.88rem;
                height: 7.75rem;
            }

            .y-box {
                position: relative;
                width: 29.38rem;
                height: 7.75rem;
                margin: 4.88rem 0 1.13rem;
            }

            .screen4-title1 {
                margin-top: 8.38rem;
                width: 36.81rem;
                height: 5rem;
            }

            .screen4-title2 {
                margin-top: 8.88rem;
                font-size: 2.25rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #BA9D6E;
                line-height: 3.13rem;
            }

            .screen4-p1 {
                margin-top: 4.88rem;
                width: 19.25rem;
                height: 17.13rem;
            }

            .screen4-p2 {
                margin-top: 7.25rem;
                width: 19.13rem;
                height: 17.13rem;
            }

            .screen4-p1, .screen4-p2 {
                position: relative;
                padding-top: 5.25rem;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                & > div {
                    width: 100%;
                    font-size: 6.25rem;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #BA9D6E;
                    line-height: 8.75rem;
                    text-align: center;
                    span {
                        font-size: 4.75rem;
                    }
                }
            }

            .screen5-title {
                margin-top: 7.13rem;
                width: 36.81rem;
                height: 5rem;
            }

            .screen5-content {
                margin-top: 6.63rem;
                width: 44rem;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 0.63rem;
            }

            .screen5-item {
                width: 14rem;
                height: 8.44rem;
                background: #FFFFFF;
                box-sizing: border-box;

                // &:hover {
                //     .item4-top {
                //         border: 0.17rem solid #BA9D6E;
                //         border-bottom: none;
                //     }

                //     .item4-bottom {
                //         background: #BA9D6E;
                //         color: #FFFFFF;
                //         border: 0.17rem solid #BA9D6E;
                //         border-top: none;
                //     }
                // }
            }

            .item4-top {
                width: 100%;
                height: 5.38rem;
                border: 0.06rem solid #D0D0D0;
                border-bottom: none;
                box-sizing: border-box;
            }

            .item4-bottom {
                width: 100%;
                height: ~"calc(100% - 5.38rem)";
                background: #f2f2f2;
                font-size: 1.38rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 1.88rem;
                border: 0.06rem solid #D0D0D0;
                border-top: none;
                box-sizing: border-box;
            }

            .selected-item {
                .item4-top {
                    border: 0.17rem solid #BA9D6E;
                    border-bottom: none;
                }

                .item4-bottom {
                    background: #BA9D6E;
                    color: #FFFFFF;
                    border: 0.17rem solid #BA9D6E;
                    border-top: none;
                }
            }

            .screen5-btn {
                margin-top: 5.38rem;
                width: 16.25rem;
                height: 4.38rem;
                background: #BA9D6E;
                font-size: 1.5rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 2.06rem;

                &:hover {
                    transform: scale(1.01);
                }
            }

            .bottom-tip {
                position: absolute;
                bottom: 1.38rem;
                margin: 0 auto;
                text-align: center;

                a {
                    font-size: 1.25rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 1.75rem;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .beian {
                    margin-right: 0.5rem;
                    width: 1.25rem;
                    height: 1.25rem;
                }
            }
        }

        .extra-style {
            justify-content: flex-start;
            // padding: 0 5.56rem;
        }

        .active {
            opacity: 1;
            z-index: 99;
        }
    }

    .up {
        .before {
            animation: fadeOutUp .5s ease-in-out;
            z-index: 98 !important;
        }

        .active {
            animation: fadeInUp 1s ease-in-out;
        }
    }

    .down {
        .before {
            animation: fadeOutDown .5s ease-in-out;
            z-index: 98 !important;
        }

        .active {
            animation: fadeInDown 1s ease-in-out;
        }
    }

    .first-load {

        .page-item,
        .active {
            animation: none;
        }
    }

    @keyframes fadeOutUp {
        from {
            top: 0;
            opacity: 1;
        }

        to {
            top: -100%;
            opacity: 1;
        }
    }

    @keyframes fadeInUp {
        from {
            top: 100%;
            opacity: 0;
        }

        25% {
            top: 0;
            opacity: 0;
        }

        to {
            top: 0;
            opacity: 1;
        }
    }

    @keyframes fadeOutDown {
        from {
            top: 0;
            opacity: 1;
        }

        to {
            top: 100%;
            opacity: 1;
        }
    }

    @keyframes fadeInDown {
        from {
            top: -100%;
            opacity: 0;
        }

        25% {
            top: 0;
            opacity: 0;
        }

        to {
            top: 0;
            opacity: 1;
        }
    }

    @keyframes up_down {
        from {
            bottom: 2.31rem;
        }

        to {
            bottom: 4.31rem;
        }
    }
}

@media screen and (min-height: 800px) {
    .mobile-home-fragment .mobile-home {
        .extra-style {
            justify-content: space-around !important;
            padding-top: 10rem !important;
            padding-bottom: 15rem !important;

            .screen2-title,
            .screen3-title,
            .screen4-title1,
            .screen5-title {
                margin-top: 0 !important;
            }
        }
    }
}

@media screen and (width: 360px), screen and (min-height: 670px) {
    .mobile-home-fragment .mobile-home {
        .extra-style {
            justify-content: space-between !important;
            padding-top: 10rem !important;
            padding-bottom: 15rem !important;

            .screen2-title,
            .screen3-title,
            .screen4-title1,
            .screen4-title2,
            .screen5-title {
                margin-top: 0 !important;
            }
        }
    }
}