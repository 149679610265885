.mobile-development {
    overflow-y: hidden;
    .page-content1 {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: scroll;
        padding-top: 6.75rem;
        z-index: 9;
    }

    .page-title {
        margin-top: 0.63rem;
        width: 27.56rem;
        height: 4.94rem;
    }

    .top-cover {
        position: absolute;
        width: 100%;
        height: 6.75rem;
        z-index: 99;
    }

    .development-bottom {
        position: relative;
        margin-top: 3.13rem;
        width: 100%;
    }

    .item-container {
        margin-top: 2rem;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0rem 0.5rem 1.5rem 0rem rgba(100, 101, 102, 0.16);
        padding: 0.63rem 1.88rem 2rem;
    }

    .item-container:first-child {
        margin-top: 0;
    }

    .item-img {
        width: 25rem;
        height: 15rem;
    }

    .item-content {
        width: 100%;
        height: 100%;
    }

    .item-title {
        font-size: 2rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #BA9D6E;
        line-height: 3rem;
    }

    .item-desc {
        margin-top: 1.13rem;
        font-size: 1.5rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 2.25rem;
    }

    .container-bottom {
        margin-top: 1rem;
        width: 100%;
    }

    .item-date {
        font-size: 1.25rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 1.75rem;
    }

    .item-btn {
        width: 10.38rem;
        height: 2.88rem;
        background: #BA9D6E;
        font-size: 1.5rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 2.13rem;
    }

    .divider {
        width: 100%;
        height: 0.06rem;
        background: #E4E4E4;
        margin: 3.75rem 0;
    }

    .pagination {
        margin-top: 3rem;
        margin-bottom: 6rem;
        display: flex;
        justify-content: center;

        .ant-pagination {

            .ant-pagination-disabled .ant-pagination-item-link,
            .ant-pagination-disabled:hover .ant-pagination-item-link,
            .ant-pagination-disabled:focus-visible .ant-pagination-item-link,
            .ant-pagination-prev .ant-pagination-item-link,
            .ant-pagination-next .ant-pagination-item-link {
                border: none;
            }

            .ant-pagination-item {
                border-radius: 50%;

                &:hover {
                    border-color: #999;

                    a {
                        color: rgba(0, 0, 0, 0.95);
                    }
                }
            }

            .ant-pagination-item-active {
                background: #000;

                &:hover {
                    opacity: .85;

                    a {
                        color: #fff;
                    }
                }

                a {
                    color: #fff;
                }
            }
        }
    }
}