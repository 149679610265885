.pc-home-fragment {
    height: 100%;
    .pc-home {
        position: relative;
        width: 100%;
        min-width: 800px;
        height: 100vh;
        overflow: hidden;

        .page-item {
            position: absolute;
            width: 100%;
            height: 100vh;
            // min-height: 700px;
            overflow: hidden;
            opacity: 0;
            z-index: 9;

            .screen-bg-video {
                position: absolute;
                min-width: 100%;
                min-height: 100%;
                z-index: -2;
            }

            .screen-video-cover {
                position: absolute;
                width: 100%;
                height: 100vh;
                min-height: 900px;
                background: rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5px) brightness(90%);
                z-index: -1;
            }

            .screen-bg {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: -2;
            }

            .screen-bg1 {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                z-index: -2;
                min-width: 150vh;
            }

            .page-num {
                position: fixed;
                right: 5.44rem;
                bottom: 2.19rem;
                width: 3.38rem;
                height: 6.44rem;
                z-index: 99;
            }

            .next-arrow {
                position: fixed;
                margin: 0 auto;
                bottom: 2.31rem;
                cursor: pointer;
                animation: up_down .75s infinite ease-in-out alternate;
                z-index: 100;
            }

            .screen1-title {
                font-size: 3.44rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 5.94rem;
                letter-spacing: 5px;
                text-shadow: 0px 0px 0px rgba(0, 24, 26, 0.5);
            }

            .screen1-desc {
                margin-top: 0.63rem;
                font-size: 2.2rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 2.63rem;
                letter-spacing: 29px;
            }

            .screen2-title {
                width: 83.19rem;
                height: 3.56rem;
            }

            .screen2-content {
                margin-top: 6.44rem;
            }

            .screen2-item {
                margin-top: 4rem;

                &:first-child {
                    margin-top: 0;
                }
            }

            .item2-img {
                width: 12.13rem;
                height: 3.38rem;
            }

            .item2-content {
                position: relative;
                margin-left: 4.31rem;
                width: 56.63rem;
                height: 9.5rem;
                padding: 1.94rem 2.4rem;
                font-size: 1.38rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 2.81rem;
                display: flex;
                align-items: center;
            }

            .item2-content-bg {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
            }

            .screen3-title {
                margin-top: 11.25rem;
                width: 33.38rem;
                height: 6.75rem;
            }

            .service-tip {
                margin-top: 2rem;
                font-size: 1.63rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 2.31rem;
                letter-spacing: 2px;
            }

            .service-bottom {
                margin-top: 4.38rem;
                width: 100%;
                height: ~"calc(100% - 24.88rem)";
                background: #fff;
                padding: 5.25rem 10rem 8rem;
                overflow-y: scroll;
            }

            .bottom-title {
                position: relative;
                font-size: 2.13rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ba9d6e;
                line-height: 3rem;

                &::before {
                    content: ' ';
                    position: absolute;
                    left: -4.88rem;
                    top: 1.25rem;
                    width: 3.38rem;
                    height: 0.25rem;
                    background: #BA9D6E;
                    border-radius: 0.19rem;
                }

                &::after {
                    content: ' ';
                    position: absolute;
                    right: -4.88rem;
                    top: 1.25rem;
                    width: 3.38rem;
                    height: 0.25rem;
                    background: #BA9D6E;
                    border-radius: 0.19rem;
                }
            }

            .steps-box {
                position: relative;
                margin-top: 11.69rem;
                width: 71.94rem;
                height: 7.63rem;
                z-index: 10;
            }

            .path {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: -1;
            }

            .step-item {
                position: absolute;
                width: 5.63rem;
                height: 5.63rem;

                &:hover {

                    .bottom-detail,
                    .top-detail {
                        display: block;
                    }
                }
            }

            .step-text {
                position: absolute;
                width: 20rem;
                left: -7.185rem;
                font-size: 1.44rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2B2B2B;
                line-height: 2rem;
                white-space: nowrap;
                text-align: center;
            }

            .step-icon {
                width: 100%;
                height: 100%;
                cursor: pointer;
            }

            .detail-box {
                position: absolute;
                left: -8.125rem;
                width: 21.88rem;
                z-index: 99;
            }

            .detail-bg {
                width: 100%;
            }

            .detail-text {
                position: absolute;
                width: 17.06rem;

            }

            .bottom-detail {
                display: none;
                top: 6.2rem;
            }

            .top-detail {
                display: none;
                bottom: 6rem;
            }

            .detail-title {
                font-size: 1.13rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #9e845a;
                line-height: 1.56rem;
            }

            .detail-divider {
                margin-top: 0.75rem;
                width: 100%;
                height: 0.16rem;
                background: #c1a674;
            }

            .detail-content {
                margin-top: 0.63rem;
                font-size: 1rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 1.88rem;
            }

            .screen4-title1 {
                font-size: 2.5rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 3.5rem;
                letter-spacing: 2px;
                opacity: .8;
            }

            .screen4-title2 {
                margin-top: 2.5rem;
                font-size: 3.38rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333;
                line-height: 4.69rem;
                letter-spacing: 3px;
            }

            .screen4-content {
                margin-top: 10.5rem;
            }

            .screen4-item {
                position: relative;
                width: 30.63rem;
                height: 18.13rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 1.25rem;
                padding-right: 7.25rem;

                img {
                    position: absolute;
                    width: 18.75rem;
                    height: 18.13rem;
                    top: 0;
                    right: 0;
                    z-index: -1;
                }

                &:first-child {
                    margin-right: 23.56rem;
                    padding-right: 3.63rem;
                }
            }

            .screen4-label {
                font-size: 2rem;
                font-family: STYuanti-SC-Regular, STYuanti-SC;
                font-weight: 400;
                color: #BA9D6E;
                line-height: 2.81rem;
                letter-spacing: 3px;
            }

            .screen4-num {
                margin-top: 3.12rem;
                font-size: 7rem;
                font-family: Reeji-CloudRuiHei-GBK, Reeji-CloudRuiHei;
                font-weight: normal;
                color: #BA9D6E;
                line-height: 7.94rem;
            }

            .screen4-unit {
                font-size: 5.19rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
            }

            .screen5-title {
                width: 36.81rem;
                height: 5rem;
            }

            .screen5-content {
                margin-top: 4.44rem;
                width: 86.25rem;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 1.88rem;
            }

            .screen5-item {
                width: 20.12rem;
                height: 10.63rem;
                background: #FFFFFF;
                box-sizing: border-box;

                &:hover {
                    .item4-top {
                        border: 0.17rem solid #BA9D6E;
                        border-bottom: none;
                    }

                    .item4-bottom {
                        background: #BA9D6E;
                        color: #FFFFFF;
                        border: 0.17rem solid #BA9D6E;
                        border-top: none;
                    }
                }
            }

            .item4-top {
                width: 100%;
                height: 8.06rem;
                border: 0.06rem solid #D0D0D0;
                border-bottom: none;
                box-sizing: border-box;
            }

            .item4-bottom {
                width: 100%;
                height: ~"calc(100% - 8.06rem)";
                background: #f2f2f2;
                font-size: 1.38rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 1.88rem;
                border: 0.06rem solid #D0D0D0;
                border-top: none;
                box-sizing: border-box;
            }

            .selected-item {
                .item4-top {
                    border: 0.17rem solid #BA9D6E;
                    border-bottom: none;
                }

                .item4-bottom {
                    background: #BA9D6E;
                    color: #FFFFFF;
                    border: 0.17rem solid #BA9D6E;
                    border-top: none;
                }
            }

            .screen5-btn {
                margin-top: 3.19rem;
                width: 16.25rem;
                height: 4.38rem;
                background: #BA9D6E;
                font-size: 1.5rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 2.06rem;

                &:hover {
                    transform: scale(1.01);
                }
            }

            .bottom-tip {
                position: absolute;
                bottom: 1.38rem;
                margin: 0 auto;
                text-align: center;

                a {
                    font-size: 1.25rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 1.75rem;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .beian {
                    margin-right: 0.5rem;
                    width: 1.25rem;
                    height: 1.25rem;
                }
            }
        }

        .extra-style {
            .screen2-title {
                margin-top: 8rem !important;
            }
        }

        .screen3-content {
            padding: 0 5.56rem;
        }

        .active {
            opacity: 1;
            z-index: 99;
        }

        .scroll-track {
            position: fixed;
            top: 0;
            right: 2px;
            width: 10px;
            height: 100vh;
            z-index: 100;
        }

        .scroll-bar {
            position: absolute;
            width: 100%;
            height: 20%;
            background: #ba9d6e;
            border-radius: 5px;
            transition: all 1s;
        }
    }

    .up {
        .before {
            animation: fadeOutUp .5s ease-in-out;
            z-index: 98 !important;
        }

        .active {
            animation: fadeInUp 1s ease-in-out;
        }
    }

    .down {
        .before {
            animation: fadeOutDown .5s ease-in-out;
            z-index: 98 !important;
        }

        .active {
            animation: fadeInDown 1s ease-in-out;
        }
    }

    .first-load {

        .page-item,
        .active {
            animation: none;
        }
    }

    @keyframes fadeOutUp {
        from {
            top: 0;
            opacity: 1;
        }

        to {
            top: -100vh;
            opacity: 1;
        }
    }

    @keyframes fadeInUp {
        from {
            top: 100vh;
            opacity: 0;
        }

        25% {
            top: 0;
            opacity: 0;
        }

        to {
            top: 0;
            opacity: 1;
        }
    }

    @keyframes fadeOutDown {
        from {
            top: 0;
            opacity: 1;
        }

        to {
            top: 100vh;
            opacity: 1;
        }
    }

    @keyframes fadeInDown {
        from {
            top: -100vh;
            opacity: 0;
        }

        25% {
            top: 0;
            opacity: 0;
        }

        to {
            top: 0;
            opacity: 1;
        }
    }

    @keyframes up_down {
        from {
            bottom: 2.31rem;
        }

        to {
            bottom: 4.31rem;
        }
    }
}